import { DrawingManager } from '@react-google-maps/api';
import Color from 'color';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { getCSS } from '../../../functions/css.function';
import { Badge } from '../../badge/badge.component';
import { Button } from '../../button/button.component';
import { useMapsTheme } from '../hook/theme.hook';
import { MapsContext } from '../maps.component';
import { Popover } from '../../popover/popover.component';
import { MapsType } from '../maps.component';

const overlayToGeoJson = (
  overlays: Array<MapsType.Overlay.Building>,
): MapsType.Data => {
  return {
    type: 'FeatureCollection',
    features: overlays
      .map(({ id, type, overlay }) => {
        switch (type) {
          case google.maps.drawing.OverlayType.POLYGON:
            const paths: Array<Array<[number, number]>> = [];

            overlay
              .getPaths()
              .forEach((path) =>
                paths.push(
                  path.getArray().map(({ lat, lng }) => [lng(), lat()]),
                ),
              );

            const type =
              Array.isArray(paths) && paths.length === 1
                ? 'Polygon'
                : 'MultiPolygon';

            return {
              type: 'Feature',
              properties: overlay.get('allProperties'),
              geometry: {
                type: type,
                coordinates: type === 'Polygon' ? paths : [paths],
              },
            } as GeoJSON.Feature<
              GeoJSON.Polygon | GeoJSON.MultiPolygon,
              MapsType.Property
            >;

          default:
            return null;
        }
      })
      .filter((item) => !!item),
  };
};

const duplicateOverlay = ({
  id,
  type,
  overlay,
}: Pick<
  MapsType.Overlay.Building,
  'id' | 'type' | 'overlay'
>): MapsType.Overlay.Building => {
  switch (type) {
    case google.maps.drawing.OverlayType.POLYGON:
      const paths: Array<Array<{ lat: number; lng: number }>> = [];

      overlay
        .getPaths()
        .forEach((path, index) =>
          paths.push(
            path.getArray().map(({ lat, lng }) => ({ lat: lat(), lng: lng() })),
          ),
        );

      const duplicatePolygon = new google.maps.Polygon({
        fillColor: overlay.get('fillColor'),
        fillOpacity: overlay.get('fillOpacity'),
        strokeColor: overlay.get('strokeColor'),
        strokeOpacity: overlay.get('strokeOpacity'),
        strokeWeight: overlay.get('strokeWeight'),
        zIndex: overlay.get('zIndex'),
        visible: true,
        map: null,
      });

      duplicatePolygon.set('allProperties', overlay.get('allProperties'));
      duplicatePolygon.set('id', id);
      duplicatePolygon.setPath(overlay.getPath().getArray());
      duplicatePolygon.set('calcPath', paths);

      return {
        id,
        type,
        overlay: duplicatePolygon,
      };
  }
};

export const MapsDrawingModule = observer(() => {
  const {
    load,
    actions: {
      setCurrentInfoWindow,
      setMode,
      setOverlaysDrawing,
      setSaveOverlayInitial,
      setSaveOverlayDelete,
      setDataLoad,
      setAnOverlayIsEditing,
    },
    store: {
      currentInfoWindow,
      mode,
      overlaysDrawing,
      saveOverlayInitial,
      saveOverlayDelete,
      loadData,
      anOverlayIsEditing,
    },
    modules: { drawing },
    overlaysInitial,
    map,
  } = useContext(MapsContext);

  const mapsTheme = useMapsTheme({ load });
  const drawingManagerRef = useRef<DrawingManager>(null);

  const allOverlays = [...(overlaysDrawing || []), ...(overlaysInitial || [])];

  const onCancel = useCallback(() => {
    overlaysInitial.forEach(({ id, type, overlay }) => {
      const overlayInitial = saveOverlayInitial.find((item) => item.id === id);
      const calcPath = overlayInitial?.overlay.get('calcPath');

      if (!overlayInitial) return;

      switch (type) {
        case google.maps.drawing.OverlayType.POLYGON:
          if (Array.isArray(calcPath) && calcPath.length === 1) {
            overlay.setPath(calcPath[0]);
          } else {
            overlay.setPaths(calcPath);
          }

          overlay.set(
            'allProperties',
            overlayInitial.overlay.get('allProperties'),
          );
          overlay.set('id', overlayInitial.id);
          overlay.setOptions({
            fillColor: overlayInitial.overlay.get('fillColor'),
            fillOpacity: overlayInitial.overlay.get('fillOpacity'),
            strokeColor: overlayInitial.overlay.get('strokeColor'),
            strokeOpacity: overlayInitial.overlay.get('strokeOpacity'),
            strokeWeight: overlayInitial.overlay.get('strokeWeight'),
            visible: true,
            strokePosition: overlayInitial.overlay.get('strokePosition'),
            zIndex: overlayInitial.overlay.get('zIndex'),
          });

          if (!overlay.getMap()) {
            overlay.setMap(map);
            overlay.setVisible(true);
          }
          break;
      }
    });

    overlaysDrawing.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    setOverlaysDrawing([]);
    setSaveOverlayInitial([]);
    setSaveOverlayDelete([]);

    setMode('read');
    setAnOverlayIsEditing(false);
  }, [
    overlaysDrawing,
    saveOverlayDelete,
    saveOverlayInitial,
    allOverlays,
    map,
  ]);

  const onSave = useCallback(() => {
    const idsOfDelete = saveOverlayDelete.map(({ id }) => id);

    const newList = allOverlays.filter(
      (element) => !idsOfDelete.includes(element.id),
    );

    drawing?.onSubmit?.(overlayToGeoJson(newList));

    overlaysDrawing.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    saveOverlayDelete.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    saveOverlayInitial.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    overlaysInitial.forEach(({ overlay }) => {
      overlay.setMap(null);
    });

    setOverlaysDrawing([]);
    setSaveOverlayInitial([]);
    setSaveOverlayDelete([]);

    setMode('read');
    setDataLoad(false);
    setAnOverlayIsEditing(false);
  }, [overlaysDrawing, saveOverlayDelete, saveOverlayInitial, allOverlays]);

  const onEdit = useCallback(() => {
    const duplicateList: MapsType.Overlay.Building[] = [];

    overlaysInitial?.forEach(({ id, type, overlay }) => {
      duplicateList.push(duplicateOverlay({ id, type, overlay }));
    });

    setSaveOverlayInitial(duplicateList || []);
    setMode('edit');
  }, [setMode, overlaysInitial]);

  //! Activation des options d'édition et de déplacement des overlays
  useEffect(() => {
    allOverlays.forEach(({ overlay }) => {
      if (drawing) {
        const value = mode === 'edit';
        overlay.setEditable(value);
        overlay.setDraggable(value);
      }
    });
  }, [allOverlays, mode, drawing]);

  //! Activation des événements de l'overlay pour connaitre le changement de position ou de structure
  useEffect(() => {
    const listeners: Array<google.maps.MapsEventListener> = [];
    allOverlays.forEach(({ type, overlay }) => {
      switch (type) {
        case google.maps.drawing.OverlayType.POLYGON:
          overlay.getPaths().forEach((path) => {
            listeners.push(
              google.maps.event.addListener(path, 'set_at', () => {
                setAnOverlayIsEditing(true);
              }),
            );
            listeners.push(
              google.maps.event.addListener(path, 'insert_at', () => {
                setAnOverlayIsEditing(true);
              }),
            );
          });

          break;
      }
    });

    return () => {
      listeners.forEach((listener) => {
        google.maps.event.removeListener(listener);
      });
    };
  }, [allOverlays]);

  return load && drawing ? (
    <>
      {mode === 'edit' && (
        <DrawingManager
          ref={drawingManagerRef}
          onOverlayComplete={(e) => {
            const type = e.type;
            const overlay = e.overlay;

            if (!overlay) return;

            overlay.set('id', crypto.randomUUID());
            overlay.setDraggable(true);

            switch (type) {
              case google.maps.drawing.OverlayType.POLYGON:
                (overlay as google.maps.Polygon).setEditable(true);
                (overlay as google.maps.Polygon).setDraggable(true);
                break;
            }

            const newOverlay = {
              type: e.type,
              overlay: e.overlay!,
            } as MapsType.Overlay.Building;

            setOverlaysDrawing((prev) => [...prev, newOverlay]);
          }}
          options={{
            drawingControl: true,
            circleOptions: {
              ...(mapsTheme?.drawingManagerStyle?.circle || {}),
              editable: true,
              clickable: true,
              draggable: true,
            },
            polygonOptions: {
              ...(mapsTheme?.drawingManagerStyle?.polygon || {}),
              editable: true,
              geodesic: true,
              clickable: true,
              draggable: true,
            },
            polylineOptions: {
              ...(mapsTheme?.drawingManagerStyle?.polyline || {}),
              editable: true,
              geodesic: true,
              clickable: true,
              draggable: true,
            },
            rectangleOptions: {
              ...(mapsTheme?.drawingManagerStyle?.rectangle || {}),
              editable: true,
              clickable: true,
              draggable: true,
            },
            markerOptions: {
              ...(mapsTheme?.drawingManagerStyle?.marker || {}),
              crossOnDrag: true,
              draggable: true,
              clickable: true,
              icon: {
                url: 'https://cellar-c2.services.clever-cloud.com/yoonite-static/presto_one_marker.png',
                scaledSize: new google.maps.Size(70, 70),
              },
              label: {
                text: 'test',
                color: new Color(getCSS('--color-primary-over')).hex(),
                fontSize: '16px',
                className: 'maps-marker-label',
              },
              title: 'ddddd',
              optimized: true,
              zIndex: 1000,
            },
            drawingControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP,
              drawingModes: Object.entries(drawing?.modes || {})
                .filter(([_, value]) => !!value)
                .map(([key]) => key as MapsType.Overlay.Type),
            },
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: '15px',
          right: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          backgroundColor: getCSS('--color-background'),
          padding: '12px',
          borderRadius: '8px',
          boxShadow: `0px 0px 10px ${getCSS('--color-shadow')}`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {mode === 'edit' && (
            <>
              <Button
                handleEvent={{
                  click: onCancel,
                }}
                config={{
                  text: 'Annuler',
                  mode: 'fill',
                }}
              />
              <Popover.Confirm
                config={{
                  title: 'Action importante',
                  description:
                    'Voulez-vous vraiment enregistrer les modifications ?',
                }}
                handleEvent={{
                  confirm: onSave,
                }}
              >
                <Button
                  config={{
                    text: 'Sauvegarder',
                    mode: 'stroke',
                    color: 'primary',
                    disabled:
                      !overlaysDrawing.length &&
                      !saveOverlayDelete.length &&
                      !anOverlayIsEditing,
                  }}
                />
              </Popover.Confirm>
            </>
          )}
          {mode === 'read' && (
            <Button
              handleEvent={{
                click: onEdit,
              }}
              config={{
                text: 'Modifier',
                mode: 'fill',
              }}
            />
          )}
        </div>

        <div className="drawingEditBlock__state">
          <Badge
            config={{
              text: mode === 'edit' ? 'Edition' : 'Lecture',
              size: 'xlarge',
              color: mode === 'edit' ? 'warn' : 'success',
            }}
          />
        </div>
      </div>
    </>
  ) : null;
});
