import { gql } from '@apollo/client';

export const simulateTariffGql = gql`
  mutation SimulateTariff($simulateParams: SimulateTariffDto!) {
    simulateTariff(
        simulateParams: $simulateParams) {
        segments {
            startAmount
            endAmount
            duration
            type
        }
        booking_time
        expiration_time
    }
}
`;

  