import { Button, FilterExpanded, Page, Table, useMyUrl, useQuery, Widget, Zone } from "@gimlite/watermelon"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Query, QueryTerminalActionsArgs, TerminalActionEntity } from "../../../../client/graphql"
import { formatDate } from "../../../common/date"
import { dateSorter, defaultSorter } from "../../../common/table-sorters"
import { terminalActionsGql } from "../gql/terminal-actions.gql"

type RenderedTerminalAction = TerminalActionEntity & {
    siteName: string
}

export const TerminalActions = () => {

    const navigate = useNavigate()
    const { getParamsUrl, setParamsUrl } = useMyUrl()

    const { data, loading, error, refetch } = useQuery<
        { terminalActions: Query['terminalActions'] },
        QueryTerminalActionsArgs
    >(terminalActionsGql, {
        variables: { limit: 15, ...getParamsUrl },  
    })

    const renderedTerminalActions = useMemo(() => 
        ({
            list: data?.terminalActions.list.map(action => ({
                    ...action,
                    siteName: action.site?.name || ''
            })) || [],
            paging: data?.terminalActions.paging || {
                count: 1,
                current: 1,
                limit: 15
            }
        })
    , [data])

    return (
        <Page>
            <Widget
                state={{ loading, error, refetch }}
                config={{ title: 'Terminal Actions' }}
            >
                <Zone
                    config={{
                        zones: [['filter'], ['table']],
                        rows: ['min-content', '1fr'],
                        columns: ['1fr'],
                    }}
                >
                    <Zone.Area config={{ area: 'filter' }}>
                        <FilterExpanded
                            data={{ value: getParamsUrl }}
                            handleEvent={{
                                submit: (data: any) => {
                                    setParamsUrl({ ...getParamsUrl, ...data, page: 1 })
                                }
                            }}
                        >
                            <FilterExpanded.Fields>

                            </FilterExpanded.Fields>
                            <FilterExpanded.Actions>
                                <Button
                                    config={{
                                        text: "New",
                                        type: { value: 'button' }
                                    }}
                                    handleEvent={{
                                        click: () => navigate('/adm/new-terminal-action')
                                    }}
                                />
                            </FilterExpanded.Actions>
                        </FilterExpanded>
                    </Zone.Area>
                    <Zone.Area config={{ area: 'table' }}>
                        <Table<RenderedTerminalAction>
                            data={renderedTerminalActions}

                            config={{
                                pagination: 'click',
                                columns: [
                                    {
                                        key: 'siteName',
                                        title: 'Site',
                                        sort: defaultSorter('siteName')
                                    },
                                    { 
                                        key: 'type', 
                                        title: 'Type',
                                        sort: defaultSorter('type')
                                    },
                                    {
                                        key: 'status',
                                        title: 'Status',
                                        sort: defaultSorter('status')
                                    },
                                    { 
                                        key: 'start',
                                        title: 'Start date',
                                        render: ({ start }) => formatDate(start),
                                        sort: dateSorter('start')
                                    },
                                    {
                                        key: 'deadline',
                                        title: 'Deadline',
                                        render: ({ deadline }) => deadline? formatDate(deadline) : '',
                                        sort: dateSorter('deadline')
                                    }
                                ],
                                actions: {
                                    read: true
                                }
                            }}

                            handleEvent={{
                                paging: ({ page, limit }) => {
                                    setParamsUrl({ ...getParamsUrl, page, limit })
                                },
                                read: ({ _id }) => {
                                    navigate(`/adm/terminal-actions/${_id}`)
                                }
                            }}
                        />
                    </Zone.Area>
                </Zone>
            </Widget>
        </Page>
    )
    
}