import { gql } from '@apollo/client';

export const clientGql = gql`
  query client($clientId: String!, $recordsPage: Int, $recordsLimit: Int) {
    client(clientId: $clientId) {
      _id
      slug
      siret
    }
  }
`;
