import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import MainUse from './main';
import { AdminUsersSearch } from './pages/admin-users/view/admin-users.search';
import { BucketDetails } from './pages/buckets/view/bucket.details';
import { BucketsSearch } from './pages/buckets/view/buckets.search';
import { FragmentDetails } from './pages/buckets/view/fragments.details';
import { FragmentSearch } from './pages/buckets/view/fragments.search';
import { CameraDetails } from './pages/cameras/view/cameras.details';
import { CameraSearch } from './pages/cameras/view/cameras.search';
import { ClientsDetailsFinance } from './pages/clients/view/category/finance.category';
import { ClientsDetailsGlobal } from './pages/clients/view/category/global.category';
import { ClientsDetailsUps } from './pages/clients/view/category/ups.category';
import { ClientsDetails } from './pages/clients/view/clients.details';
import { ClientsSearch } from './pages/clients/view/clients.search';
import { ControlUsersDetails } from './pages/control-users/view/control-users.details';
import { ControlUsersSearch } from './pages/control-users/view/control-users.search';
import { MailsSearch } from './pages/mails/view/mails.search';
import { Monitoring } from './pages/monitoring/view/monitoring';
import { OperatorsDetails } from './pages/operators/view/operators.details';
import { OperatorsSearch } from './pages/operators/view/operators.search';
import { ParkingRightsDetails } from './pages/parking-rights/view/parking-rights.details';
import { ParkingRightsList } from './pages/parking-rights/view/parking-rights.search';
import { ParkingsSearch } from './pages/parkings/view/parkings.search';
import { ContractsSearch } from './pages/payment-contracts/view/contracts.search';
import { ProfilDetails } from './pages/profil/view/profil.details';
import { ServiceCards } from './pages/service-cards/service-cards';
import { SessionsSearch } from './pages/sessions/view/sessions.search';
import { SimulateTariffs } from './pages/simulate-tarrifs/view/simulate.tariffs';
import { SitesDetailsEnergy } from './pages/sites/view/categorys/energy.category';
import { SitesDetailsEvent } from './pages/sites/view/categorys/event.category';
import { SitesDetailsFeedback } from './pages/sites/view/categorys/feedback.category';
import { SitesDetailsFinance } from './pages/sites/view/categorys/finance.category';
import { SitesDetailsFragment } from './pages/sites/view/categorys/fragment.category';
import { SitesDetailsGlobal } from './pages/sites/view/categorys/global.category';
import { SitesDetails } from './pages/sites/view/sites.details';
import { SitesSearch } from './pages/sites/view/sites.search';
import { TerminalAction } from './pages/terminal-actions/view/terminal-action';
import { TerminalActions } from './pages/terminal-actions/view/terminal-actions';
import { TransactionsSearch } from './pages/transactions/view/transactions.search';
import { TranslationsDetails } from './pages/translations/view/translations.details';
import { TranslationsSearch } from './pages/translations/view/translations.search';
import { UpsDetails } from './pages/ups/view/ups.details';
import { UpsSearch } from './pages/ups/view/ups.search';
import { Carto } from './pages/carto/view/carto';

export const CustomRouter = observer(() => {
  const { SSOStore } = useRootStore();

  const sitemap = [
    {
      path: '*',
      element: <Navigate to={`/onstreet/parking-rights`} replace />,
    },
    {
      path: '/',
      element: <MainUse />,
      children: [
        {
          index: true,
          element: <Navigate to={`/onstreet/parking-rights`} replace />,
        },
        {
          path: 'onstreet',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={`${window.location.pathname}/parking-rights`}
                  replace
                />
              ),
            },
            {
              path: 'parking-rights',
              element: <ParkingRightsList />,
            },
            {
              path: 'parking-rights/:parkingRightId',
              element: <ParkingRightsDetails />,
            },
            {
              path: 'clients',
              element: <ClientsSearch />,
            },
            {
              path: 'clients/:clientId',
              element: <ClientsDetails />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={`${window.location.pathname}/global`}
                      replace
                    />
                  ),
                },
                {
                  path: 'global',
                  element: <ClientsDetailsGlobal />,
                },
                {
                  path: 'ups',
                  element: <ClientsDetailsUps />,
                },
                {
                  path: 'finance',
                  element: <ClientsDetailsFinance />,
                },
              ],
            },
            {
              path: 'cartography',
              element: <Carto />,
            },
            {
              path: 'simulate-tariffs',
              element: <SimulateTariffs />,
            },
            {
              path: 'sites',
              element: <SitesSearch />,
            },
            {
              path: 'sites/:siteId',
              element: <SitesDetails />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to={`${window.location.pathname}/global`}
                      replace
                    />
                  ),
                },
                {
                  path: 'global',
                  element: <SitesDetailsGlobal />,
                },
                {
                  path: 'finance',
                  element: <SitesDetailsFinance />,
                },
                {
                  path: 'energy',
                  element: <SitesDetailsEnergy />,
                },
                {
                  path: 'event',
                  element: <SitesDetailsEvent />,
                },
                {
                  path: 'fragment',
                  element: <SitesDetailsFragment />,
                },
                {
                  path: 'feedback',
                  element: <SitesDetailsFeedback />,
                },
              ],
            },
            {
              path: 'ups',
              element: <UpsSearch />,
            },
            {
              path: 'ups/:upsId',
              element: <UpsDetails />,
            },
          ],
        },
        {
          path: 'prestoscan',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate to={`${window.location.pathname}/users`} replace />
              ),
            },
            {
              path: 'users',
              element: <ControlUsersSearch />,
            },
            {
              path: 'users/:userId',
              element: <ControlUsersDetails />,
            },
            {
              path: 'cameras',
              element: <CameraSearch />,
            },
            {
              path: 'cameras/:cameraId',
              element: <CameraDetails />,
            },
          ],
        },
        {
          path: 'offstreet',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={`${window.location.pathname}/operators`}
                  replace
                />
              ),
            },
            {
              path: 'operators',
              element: <OperatorsSearch />,
            },
            {
              path: 'operators/:operatorId',
              element: <OperatorsDetails />,
            },
            {
              path: 'parkings',
              element: <ParkingsSearch />,
            },
            {
              path: 'sessions',
              element: <SessionsSearch />,
            },
          ],
        },
        {
          path: 'payments',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={`${window.location.pathname}/contracts`}
                  replace
                />
              ),
            },
            {
              path: 'contracts',
              element: <ContractsSearch />,
            },
            {
              path: 'transactions',
              element: <TransactionsSearch />,
            },
          ],
        },
        {
          path: 'adm',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to={`${window.location.pathname}/translations`}
                  replace
                />
              ),
            },
            {
              path: 'translations',
              element: <TranslationsSearch />,
            },
            {
              path: 'translations/:dictionaryKey',
              element: <TranslationsDetails />,
            },
            {
              path: 'buckets',
              element: <BucketsSearch />,
            },
            {
              path: 'buckets/:bucketId',
              element: <BucketDetails />,
            },
            {
              path: 'fragments',
              element: <FragmentSearch />,
            },
            {
              path: 'fragmentsByRef/:fragmentRef',
              element: <FragmentDetails />,
            },
            {
              path: 'fragments/:fragmentId',
              element: <FragmentDetails />,
            },
            {
              path: 'admin-users',
              element: <AdminUsersSearch />,
            },
            {
              path: 'service-cards',
              element: <ServiceCards />,
            },
            {
              path: 'monitoring',
              element: <Monitoring />,
            },
            {
              path: 'terminal-actions',
              element: <TerminalActions />,
            },
            {
              path: 'terminal-actions/:terminalActionId',
              element: <TerminalAction />,
            },
            {
              path: 'new-terminal-action',
              element: <TerminalAction newAction={true} />,
            }
          ],
        },
        {
          path: 'mails',
          element: <MailsSearch />,
        },
        {
          path: 'settings',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: (
                <Navigate to={`${window.location.pathname}/profil`} replace />
              ),
            },
            {
              path: 'profil',
              element: <ProfilDetails />,
            },
          ],
        },
      ],
    },
  ];

  return SSOStore.authenticated && SSOStore.user ? (
    <RouterProvider router={createBrowserRouter(sitemap)}></RouterProvider>
  ) : null;
});
