import {
  Button,
  Code,
  DatePicker,
  FilterExpanded,
  Form,
  Line,
  Page,
  Select,
  Widget,
  Zone,
  lastWeek,
  useMutation,
  useQuery,
} from '@gimlite/watermelon';
import React, { useMemo, useState } from 'react';
import { MutationSimulateTariffArgs, Query } from '../../../../client/graphql';
import { simulateTariffGql } from '../gql/simulateTariff.gql';
import { tariffsGql } from '../gql/tariffs.gql';

// Type Definitions
interface Tariff {
  label: string;
  value: string;
  details: any;
}

interface Segment {
  endAmount: number;
  type: string;
}

const DEFAULT_START_DATE: string = lastWeek('DATE').toISOString();

// Fetch Tariffs
const useTariffs = () => {
  const { data, loading, error, refetch } = useQuery<{
    searchTariffs: Query['searchTariffs'];
  }>(tariffsGql, {
    notification: {
      error: "Une erreur s'est produite lors de la récupération des tarifs.",
    },
  });

  // Function to recursively sanitize `__typename`
  const sanitizeData = (data: any): any => {
    if (Array.isArray(data)) {
      return data.map((item) => sanitizeData(item));
    } else if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        if (key === '__typename') return acc;
        return { ...acc, [key]: sanitizeData(data[key]) };
      }, {});
    }
    return data;
  };
  const tariffs = useMemo<Tariff[]>(() => {
    if (!data?.searchTariffs?.annemasse) return [];
    try {
      // Sanitize the `annemasse` object
      const sanitizedAnnemasse = sanitizeData(data.searchTariffs.annemasse);

      // Transform sanitized `annemasse` object into the desired format
      return Object.keys(sanitizedAnnemasse).map((key) => ({
        label: key.toUpperCase(),
        value: key,
        details: sanitizedAnnemasse[key],
      }));
    } catch (err) {
      console.error('Error sanitizing tariffs:', err);
      return [];
    }
  }, [data]);

  return { tariffs, loading, error, refetch };
};

// Simulation Logic
const useSimulation = () => {
  const [simulateTariff] = useMutation<
    { simulateTariff: any },
    MutationSimulateTariffArgs
  >(simulateTariffGql, {
    notification: {
      error: "Une erreur s'est produite lors de la simulation du tarif.",
      success: 'La simulation du tarif a été réalisée avec succès.',
      loading: 'Simulation du tarif en cours...',
    },
  });

  const simulate = async (
    startDate: string,
    tariffDescription: string | null,
  ): Promise<Segment[] | null> => {
    if (!startDate || !tariffDescription) {
      console.error('Start date or tariff description is missing');
      return null;
    }

    try {
      const { data } = await simulateTariff({
        variables: {
          simulateParams: {
            startDate,
            tariffDescription,
          },
        },
      });
      return data?.simulateTariff?.segments || null;
    } catch (err) {
      console.error('Simulation error:', err);
      return null;
    }
  };

  return { simulate };
};

// Chart Data Preparation
const prepareChartData = (segments: Segment[] | null) => {
  if (!segments) return null;
  let lastPaidAmount = 0;
  return {
    labels: segments.map((_, index) => `Step ${index + 1}`),
    datasets: [
      {
        label: 'Montant Tarifaire',
        data: segments.map((segment) => {
          if (segment.type === 'Paying') {
            lastPaidAmount = Math.max(lastPaidAmount, segment.endAmount); // Ensure the amount never decreases
          }
          return lastPaidAmount; // Keep last paid amount for "NotPaying"
        }),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderWidth: 2,
        fill: false,
        pointBackgroundColor: segments.map((segment) =>
          segment.type === 'Paying'
            ? 'rgba(75,192,192,1)'
            : 'rgba(192,75,75,1)',
        ),
        pointBorderColor: 'rgba(0,0,0,0.1)',
        pointRadius: 5,
        pointHoverRadius: 7,
        segmentType: segments.map((segment) => segment.type),
      },
    ],
  };
};

export const SimulateTariffs: React.FC = () => {
  const { tariffs, loading, error, refetch } = useTariffs();
  const { simulate } = useSimulation();

  const [startDate, setStartDate] = useState<string>(DEFAULT_START_DATE);
  const [tariffDescription, setTariffDescription] = useState<string | null>(
    null,
  );
  const [simulationResult, setSimulationResult] = useState<Segment[] | null>(
    null,
  );

  // Handlers
  const handleTarifChange = (value: string): void => {
    const selected = tariffs.find((tarif) => tarif.value === value);
    setTariffDescription(
      selected ? JSON.stringify(selected.details, null, 2) : null,
    );
  };

  const handleSimulate = async (): Promise<void> => {
    const result = await simulate(startDate, tariffDescription);
    setSimulationResult(result);
  };

  // Chart Data
  const chartData = useMemo(
    () => prepareChartData(simulationResult),
    [simulationResult],
  );

  return (
    <Page config={{ overflow: true }}>
      <Zone
        config={{
          zones: [['left', 'right']],
          rows: ['1fr'],
          columns: ['1fr', '2fr'],
        }}
      >
        <Zone.Area config={{ area: 'left' }}>
          <Zone
            config={{
              zones: [['tariff'], ['code']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            {/* Tariff Selection */}
            <Zone.Area config={{ area: 'tariff' }}>
              <Widget
                config={{ title: 'Sélectionnez un tarif' }}
                state={{ error, loading, refetch }}
              >
                <FilterExpanded>
                  <FilterExpanded.Fields>
                    <Form.Item
                      config={{
                        name: 'tarifName',
                        label: 'Nom de Tarif',
                      }}
                    >
                      <Select
                        data={{
                          items: tariffs.map(({ label, value }) => ({
                            label,
                            value,
                          })),
                        }}
                        config={{
                          mode: 'single',
                          width: 'xlarge',
                          height: 'xlarge',
                        }}
                        handleEvent={{
                          select: (value) => handleTarifChange(value as string),
                        }}
                      />
                    </Form.Item>
                  </FilterExpanded.Fields>
                </FilterExpanded>
              </Widget>
            </Zone.Area>
            <Zone.Area config={{ area: 'code' }}>
              {/* Tariff Description */}
              <Widget config={{ title: 'Configuration du Tarif' }}>
                <Code
                  data={{
                    defaultValue:
                      tariffDescription ||
                      '// Saisissez ici les tickets en JSON',
                  }}
                  config={{ lang: 'json', yMax: '70vh', resize: true }}
                  handleEvent={{
                    input: setTariffDescription,
                  }}
                />
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
        <Zone.Area config={{ area: 'right' }}>
          <Zone
            config={{
              zones: [['filtres'], ['chart']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'filtres' }}>
              {/* Filters */}
              <Widget config={{ title: 'Filtres' }}>
                <FilterExpanded
                  data={{
                    value: {
                      time: startDate,
                    },
                  }}
                  handleEvent={{
                    change: ({ time }) => setStartDate(time),
                  }}
                >
                  <FilterExpanded.Fields>
                    <Form.Item
                      config={{
                        name: 'time',
                        label: 'Date de début',
                      }}
                    >
                      <DatePicker
                        config={{
                          mode: 'single',
                          format: 'datetime',
                          width: 'xlarge',
                          height: 'xlarge',
                        }}
                      />
                    </Form.Item>
                  </FilterExpanded.Fields>
                  <FilterExpanded.Actions>
                    <Button
                      config={{ text: 'Simuler', type: { value: 'button' } }}
                      handleEvent={{ click: handleSimulate }}
                    />
                  </FilterExpanded.Actions>
                </FilterExpanded>
              </Widget>
            </Zone.Area>
            <Zone.Area config={{ area: 'chart' }}>
              {/* Chart */}
              <Widget config={{ title: 'Courbe Tarifaire' }}>
                {chartData ? (
                  <Line
                    data={chartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: true,
                          position: 'top',
                        },
                        tooltip: {
                          callbacks: {
                            label: (tooltipItem: any) => {
                              const dataset = tooltipItem.dataset;
                              const dataIndex = tooltipItem.dataIndex;
                              const segment = dataset?.data?.[dataIndex];

                              return `Montant: ${segment}`;
                            },
                          },
                        },
                        title: {
                          display: true,
                          text: 'Simulation de Tarif - Courbe des Montants',
                        },
                      },
                      scales: {
                        x: {
                          title: {
                            display: true,
                            text: 'Steps',
                          },
                        },
                        y: {
                          title: {
                            display: true,
                            text: 'Montant ',
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <p>Pas de données disponibles pour la courbe</p>
                )}
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
