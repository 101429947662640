import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Easing, Group, Tween } from '@tweenjs/tween.js';
import * as GeoJSON from 'geojson';
import { observer } from 'mobx-react-lite';
import {
  createContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDevice } from '../../hook/useDevice.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { Empty } from '../empty/empty.component';
import { useMapsTheme } from './hook/theme.hook';
import './maps.component.scss';
import { MapsDrawingModule } from './modules/drawing.module';
import { MapsLoaderModule } from './modules/loader.module';
import { MapsModalModule } from './modules/modal.module';
import { MapsEditorModule } from './modules/editor.module';

const authorizedTypes = ['Polygon', 'MultiPolygon'];

export declare namespace MapsType {
  type Props = {
    className?: string;
    data?: MapsType.Data;
    defaultPosition?: MapsType.Location | null;
    yMax?: string;
    drawing?: MapsType.Module.Drawing;
    infoWindow?: MapsType.Module.InfoWindow;
    editor?: MapsType.Module.Editor;
  };

  type Data = GeoJSON.FeatureCollection<
    GeoJSON.Polygon | GeoJSON.MultiPolygon,
    MapsType.Property
  >;

  type Property = GeoJSON.GeoJsonProperties;

  type Location = {
    lat: number;
    lng: number;
  };

  namespace Module {
    type Drawing = {
      modes: {
        polygon?: boolean;
        polyline?: boolean;
      };
      onSubmit?: (data: MapsType.Data) => void;
    };

    type InfoWindow = {
      content: (data: {
        overlay: {
          building: Overlay.Building;
          path: Array<MapsType.Location>;
          propertiesNative: MapsType.Property;
          allProperties: MapsType.Property;
          onChange: (value: MapsType.Property) => void;
          onDelete: (value: Overlay.Building) => void;
          onClose: () => void;
        };
        mode: MapsType.Mode;
      }) => React.ReactNode;
    };

    type Editor = {
      geojson?: boolean;
    };
  }

  type Mode = 'read' | 'edit';

  namespace Overlay {
    type Object =
      | google.maps.Polygon
      | google.maps.Polyline
      | google.maps.Marker
      | google.maps.Rectangle
      | google.maps.Circle;

    type Type =
      | google.maps.drawing.OverlayType.POLYGON
      | google.maps.drawing.OverlayType.POLYLINE
      | google.maps.drawing.OverlayType.MARKER
      | google.maps.drawing.OverlayType.RECTANGLE
      | google.maps.drawing.OverlayType.CIRCLE;

    type Building = {
      id: string;
      type: Extract<Overlay.Type, google.maps.drawing.OverlayType.POLYGON>;
      overlay: Extract<Overlay.Object, google.maps.Polygon>;
    };
  }
}

export const MapsContext = createContext<{
  load: boolean;
  map: google.maps.Map | null;
  store: {
    mode: MapsType.Mode;
    currentInfoWindow: {
      position: MapsType.Location;
      content: React.ReactNode;
    } | null;
    overlaysDrawing: Array<MapsType.Overlay.Building>;
    saveOverlayInitial: Array<MapsType.Overlay.Building>;
    saveOverlayDelete: Array<MapsType.Overlay.Building>;
    loadData: boolean;
    anOverlayIsEditing: boolean;
  };
  actions: {
    setDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
    setMode: React.Dispatch<React.SetStateAction<MapsType.Mode>>;
    setCurrentInfoWindow: React.Dispatch<
      React.SetStateAction<{
        position: MapsType.Location;
        content: React.ReactNode;
      } | null>
    >;
    setOverlaysDrawing: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setSaveOverlayInitial: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setSaveOverlayDelete: React.Dispatch<
      React.SetStateAction<Array<MapsType.Overlay.Building>>
    >;
    setAnOverlayIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  };
  overlaysInitial: Array<MapsType.Overlay.Building>;
  modules: {
    drawing?: MapsType.Module.Drawing;
    infoWindow?: MapsType.Module.InfoWindow;
    editor?: MapsType.Module.Editor;
  };
  dataFilter: MapsType.Data['features'];
}>({
  load: false,
  map: null,
  store: {
    loadData: false,
    mode: 'read',
    currentInfoWindow: null,
    overlaysDrawing: [],
    saveOverlayInitial: [],
    saveOverlayDelete: [],
    anOverlayIsEditing: false,
  },
  actions: {
    setMode: () => 'read',
    setCurrentInfoWindow: () => null,
    setOverlaysDrawing: () => [],
    setSaveOverlayInitial: () => [],
    setSaveOverlayDelete: () => [],
    setDataLoad: () => false,
    setAnOverlayIsEditing: () => false,
  },
  modules: {},
  overlaysInitial: [],
  dataFilter: [],
});

export const Maps = observer(
  ({
    yMax = '100%',
    data,
    defaultPosition,
    className = '',
    infoWindow,
    drawing,
    editor,
  }: MapsType.Props) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const { MapsStore } = useRootStore();
    const { isMobile } = useDevice();
    const { isLoaded: load } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: MapsStore.token || '',
      libraries: ['drawing'],
    });

    const mapsTheme = useMapsTheme({ load });

    const [mode, setMode] = useState<'read' | 'edit'>('read');

    const [currentInfoWindow, setCurrentInfoWindow] = useState<{
      position: MapsType.Location;
      content: React.ReactNode;
    } | null>(null);

    const [overlaysDrawing, setOverlaysDrawing] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    const [anOverlayIsEditing, setAnOverlayIsEditing] =
      useState<boolean>(false);

    const [loadData, setDataLoad] = useState<boolean>(false);

    const [saveOverlayInitial, setSaveOverlayInitial] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    const [saveOverlayDelete, setSaveOverlayDelete] = useState<
      Array<MapsType.Overlay.Building>
    >([]);

    //! Trie les types géométriques GeoJSON gérer dans le composant
    const dataFilter = useMemo(() => {
      if (!data) return [];
      return data?.features.filter((e) =>
        authorizedTypes.includes(e.geometry.type),
      );
    }, [data]);

    //! Transforme les types géométriques GeoJSON en objets Google Maps
    const dataOverlay = useMemo(() => {
      if (!load) return null;
      return dataFilter?.reduce(
        (acc: Array<MapsType.Overlay.Building>, { geometry, properties }) => {
          switch (geometry.type) {
            case 'Polygon':
              const polygon = new google.maps.Polygon({
                paths: geometry.coordinates.flat().flatMap(([lng, lat]) => ({
                  lat,
                  lng,
                })),
                geodesic: true,
                strokeColor: properties?.strokeColor,
                strokeOpacity: properties?.strokeOpacity,
                strokeWeight: properties?.strokeWeight,
                fillColor: properties?.fillColor,
                fillOpacity: properties?.fillOpacity,
                zIndex: properties?.zIndex,
              });

              polygon.set('allProperties', properties);
              return [
                ...acc,
                {
                  id: crypto.randomUUID(),
                  type: google.maps.drawing.OverlayType.POLYGON,
                  overlay: polygon,
                } as MapsType.Overlay.Building,
              ];

            case 'MultiPolygon':
              const multiPolygon = new google.maps.Polygon({
                paths: geometry.coordinates.flat().map((item) =>
                  item.flatMap(([lng, lat]) => ({
                    lat,
                    lng,
                  })),
                ),
                geodesic: true,
                strokeColor: properties?.strokeColor,
                strokeOpacity: properties?.strokeOpacity,
                strokeWeight: properties?.strokeWeight,
                fillColor: properties?.fillColor,
                fillOpacity: properties?.fillOpacity,
                zIndex: properties?.zIndex,
              });

              multiPolygon.set('allProperties', properties);
              return [
                ...acc,
                {
                  id: crypto.randomUUID(),
                  type: google.maps.drawing.OverlayType.POLYGON,
                  overlay: multiPolygon,
                } as MapsType.Overlay.Building,
              ];

            default:
              return acc;
          }
        },
        [],
      );
    }, [dataFilter, load]);

    //! Chargement des overlays
    useEffect(() => {
      if (load && map !== null && dataOverlay) {
        dataOverlay.forEach(({ overlay }) => {
          overlay.setVisible(true);
          overlay.setMap(map);
          overlay.setEditable(false);
          overlay.setDraggable(false);
        });
      }
    }, [dataOverlay, load, map]);

    //! Déplacement de la caméra à chaque nouvelle position
    useLayoutEffect(() => {
      if (!map || !defaultPosition || !load) return;

      const currentLatLng = new google.maps.LatLng(
        defaultPosition.lat,
        defaultPosition.lng,
      );

      const cameraOptions: google.maps.CameraOptions = {
        zoom: 0,
        heading: 0,
        tilt: 0,
        center: currentLatLng,
      };

      const group = new Group();

      const requestAnimationFrameElement = requestAnimationFrame(function loop(
        time: number,
      ) {
        group.update(time);
        requestAnimationFrame(loop);
      });

      const tween = new Tween(cameraOptions)
        .to({ zoom: 13, tilt: 180, heading: 180 }, 2500)
        .easing(Easing.Quadratic.Out)
        .onUpdate(() => {
          map.moveCamera(cameraOptions);
        })
        .onComplete(() => {
          tween.stop();
          cancelAnimationFrame(requestAnimationFrameElement);
        })
        .start();

      group.add(tween);

      return () => {
        group.allStopped();
        cancelAnimationFrame(requestAnimationFrameElement);
      };
    }, [map, defaultPosition, load]);

    return load ? (
      <div className="maps" style={{ height: yMax }}>
        <MapsContext.Provider
          value={{
            load,
            map,
            store: {
              mode,
              currentInfoWindow,
              overlaysDrawing,
              saveOverlayInitial,
              saveOverlayDelete,
              loadData,
              anOverlayIsEditing,
            },
            actions: {
              setMode,
              setCurrentInfoWindow,
              setOverlaysDrawing,
              setSaveOverlayInitial,
              setSaveOverlayDelete,
              setDataLoad,
              setAnOverlayIsEditing,
            },
            modules: {
              drawing,
              infoWindow,
              editor,
            },
            overlaysInitial: dataOverlay || [],
            dataFilter,
          }}
        >
          <div className="maps__contain">
            <GoogleMap
              mapContainerClassName={`
            maps__contain__canvas
            ${className}
          `}
              options={{
                ...(mapsTheme?.mapStyle || {}),
                scrollwheel: isMobile ? false : true,
                gestureHandling: 'greedy',
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                zoomControl: true,
                mapTypeControl: true,
                panControl: true,
                scaleControl: true,
                minZoom: 3,
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
              }}
              onClick={(e) => {
                setCurrentInfoWindow(null);
              }}
              onLoad={(e) => {
                const bounds = new google.maps.LatLngBounds(defaultPosition);
                e.fitBounds(bounds, 0);
                setMap(e);
              }}
            >
              <>
                <MapsDrawingModule />
                <MapsModalModule />
                <MapsLoaderModule />
              </>
            </GoogleMap>
            <MapsEditorModule />
          </div>
        </MapsContext.Provider>
      </div>
    ) : (
      <Empty config={{ mode: { name: 'disabled' } }} />
    );
  },
);
