import { faker } from '@faker-js/faker';
import {
  FilterExpanded,
  Form,
  Maps,
  Page,
  Select,
  useMyUrl,
  useQuery,
  Widget,
  Write,
  Zone,
} from '@gimlite/watermelon';
import * as GeoJSON from 'geojson';
import { useEffect, useMemo, useState } from 'react';
import { Query, QueryClientsArgs } from '../../../../client/graphql';
import { sortAlphabetically } from '../../../common/array';
import { ZoneModal } from '../../../components/zone-modal/zone-modal.component';
import { clientsGql } from '../gql/clients.gql';
import './carto.scss';
type Property = {
  strokeColor: string;
  strokeWeight: number;
  strokeOpacity: number;
  fillColor: string;
  fillOpacity: number;
  zoneName: string;
  zoneCode: string;
  tariffName: string;
  tariffCode: string;
  description?: string;
  zIndex: number;
};

type Polygon = {
  type: 'Feature';
  geometry: GeoJSON.Polygon;
  properties: Property;
};

type MultiPolygon = {
  type: 'Feature';
  geometry: GeoJSON.MultiPolygon;
  properties: Property;
};

type Shapes = {
  type: 'FeatureCollection';
  features: Array<Polygon | MultiPolygon>;
};

//! Fake car pas de vrai schéma déf
const tariffList = [
  {
    name: 'Tarif classique',
    code: '77777',
  },
  {
    name: 'Tarif premium',
    code: '123456',
  },
];

const defaultData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [6.108257192697666, 46.21716922353748],
              [6.133763737137373, 46.204038172848335],
              [6.135105693334029, 46.21625466436884],
              [6.140997886225418, 46.2224131408669],
            ],

            [
              [6.119532462355606, 46.21592660979159],
              [6.127711300273943, 46.21738662384431],
              [6.128948783139756, 46.20952628832524],
              [6.126067073984611, 46.21305543627764],
              [6.12180686909515, 46.21345578066061],
            ],
          ],
        ],
      },
      properties: {
        zoneName: 'Zone verte',
        zoneCode: '67890',
        tariffName: tariffList[0].name,
        tariffCode: tariffList[0].code,
        description:
          'De nombreux paquets de publication de bureau et éditeurs de pages web utilisent maintenant Lorem Ipsum comme texte modèle par défaut, et une recherche pour "lorem ipsum" révélera de nombreux sites web encore dans leur enfance. Différentes versions ont évolué au fil des ans, parfois par accident, parfois intentionnellement (avec de l\'humour injectée et ainsi de suite).',
        strokeColor: '#008000',
        strokeWeight: 2,
        strokeOpacity: 1.0,
        fillColor: '#ccffcc',
        fillOpacity: 0.5,
        zIndex: 1,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.1406, 46.2044],
            [6.1436, 46.205],
            [6.145, 46.203],
            [6.142, 46.2025],
            [6.1406, 46.2044],
          ],
        ],
      },
      properties: {
        zoneName: 'Zone rouge',
        zoneCode: '123456',
        tariffName: tariffList[0].name,
        tariffCode: tariffList[0].code,
        description:
          'De nombreux paquets de publication de bureau et éditeurs de pages web utilisent maintenant Lorem Ipsum comme texte modèle par défaut, et une recherche pour "lorem ipsum" révélera de nombreux sites web encore dans leur enfance. Différentes versions ont évolué au fil des ans, parfois par accident, parfois intentionnellement (avec de l\'humour injectée et ainsi de suite).',
        strokeColor: '#ff0000',
        strokeWeight: 2,
        strokeOpacity: 1.0,
        fillColor: '#ffaaaa',
        fillOpacity: 0.5,
        zIndex: 1,
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.15, 46.202],
            [6.153, 46.2035],
            [6.155, 46.2015],
            [6.1515, 46.2],
            [6.15, 46.202],
          ],
        ],
      },
      properties: {
        zoneName: 'Zone bleue',
        zoneCode: '123456',
        tariffName: tariffList[1].name,
        tariffCode: tariffList[1].code,
        strokeColor: '#0000ff',
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillColor: '#aaaaff',
        fillOpacity: 0.6,
        zIndex: 1,
      },
    },
  ],
} as const satisfies Shapes;

export const Carto = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl<{ clientId: string }>();
  const [data, setData] = useState<Shapes>(defaultData);
  const clientsState = useQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    variables: { limit: 300 },
  });

  const clients = useMemo(() => {
    if (!clientsState.data) return null;

    return clientsState.data.clients;
  }, [clientsState.data]);

  //! Il faudrait un schéma de donnée définit avec de faire quoi que ce soit
  //! En attendant, on génère des positions aléatoires
  const fakePosition = useMemo(() => {
    if (!getParamsUrl?.clientId) return null;
    const position = {
      lat: faker.location.latitude({ max: 46.25, min: 46.05 }),
      lng: faker.location.longitude({ max: 6.15, min: 6.05 }),
    };

    console.log(
      '%c La position change car le client a changé !',
      'color: hotpink; font-size: 18px; font-weight: bold;',
      { fakePosition: position, client: getParamsUrl?.clientId },
    );

    return position;
  }, [getParamsUrl?.clientId]);

  useEffect(() => {
    if (getParamsUrl?.clientId) {
      // clientCall({ variables: { clientId: getParamsUrl.clientId } });
    }
  }, [getParamsUrl?.clientId]);

  return (
    <Page>
      <Widget
        config={{
          title: 'Cartographie (terrain de jeu)',
          subtitle:
            'Les positions sont générées aléatoirement. Les zones et les tarifs par défaut sont toujours les mêmes. La donnée est stockée sur le front.',
        }}
      >
        <Zone
          config={{
            zones: [['filters'], ['maps']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filters' }}>
            <FilterExpanded
              data={{
                value: {
                  clientId: getParamsUrl?.clientId,
                },
              }}
              handleEvent={{
                change: (values: any) => {
                  setParamsUrl({ ...getParamsUrl, ...values });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'clientId',
                    label: 'Clients',
                  }}
                >
                  <Select
                    data={{
                      items: clients
                        ? sortAlphabetically(
                            clients.list.map(({ slug, _id }: any) => ({
                              label: slug,
                              value: _id,
                            })),
                            'label',
                          )
                        : [],
                    }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'maps' }}>
            {getParamsUrl?.clientId ? (
              <Maps
                drawing={{
                  modes: {
                    polygon: true,
                  },
                  onSubmit: (value: any) => {
                    console.log(
                      '%c Nouvelle liste',
                      'color: green; font-size: 18px; font-weight: bold;',
                      value,
                    );

                    setData(value);
                  },
                }}
                data={data}
                infoWindow={{
                  content: ({ overlay, mode }) => {
                    return (
                      <ZoneModal
                        tariffList={tariffList}
                        mode={mode}
                        type={overlay.building.type}
                        zoneName={overlay.allProperties?.zoneName}
                        zoneCode={overlay.allProperties?.zoneCode}
                        tariffCode={overlay.allProperties?.tariffCode}
                        description={overlay.allProperties?.description}
                        fillColor={overlay.allProperties?.fillColor}
                        strokeColor={overlay.allProperties?.strokeColor}
                        fillOpacity={overlay.allProperties?.fillOpacity}
                        strokeOpacity={overlay.allProperties?.strokeOpacity}
                        strokeWeight={overlay.allProperties?.strokeWeight}
                        zIndex={overlay.allProperties?.zIndex}
                        onChange={overlay.onChange}
                        onDelete={() => {
                          overlay.onDelete(overlay.building);
                        }}
                        onClose={overlay.onClose}
                      />
                    );
                  },
                }}
                editor={{
                  geojson: true,
                }}
                defaultPosition={fakePosition}
              />
            ) : (
              <div className="maps-empty">
                <Write
                  data={{
                    item: 'Veuillez sélectionner un client ...',
                  }}
                  config={{
                    mode: 'value-large',
                  }}
                ></Write>
              </div>
            )}
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
