import { gql } from '@apollo/client';

export const sitesGql = gql`
  query sites($page: Int, $limit: Int, $order: String) {
    sites(page: $page, limit: $limit, order: $order) {
      list {
        _id
        monitoring {
          _id
          status
        }
        client {
          _id
          slug
        }
        ups {
          _id
          code
        }
        name
        terminalId
        batteryRemainingAutomomy
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
