import { gql } from '@apollo/client';

export const tariffsGql = gql`
query GetAnnemasseTariffs {
  searchTariffs {
    annemasse {
      verte {
        calendar {
          duration
          name
          repeat
        }
        tariff {
          after
          duration
          name
          startAmount
          endAmount
        }
      }
      orange {
        calendar {
          duration
          name
          repeat
        }
        tariff {
          after
          duration
          name
          startAmount
          endAmount
        }
      }
    }
  }
}
`;

  