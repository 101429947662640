import {
  Badge,
  Button,
  Col,
  ColorPicker,
  Form,
  Input,
  Popover,
  Row,
  Select,
  TextArea,
  toCapitalizeCase,
  Write,
} from '@gimlite/watermelon';
import './zone-modal.component.scss';

export declare namespace ZoneModalType {
  type Props = {
    className?: string;
    fillColor: string;
    strokeColor: string;
    fillOpacity: number;
    strokeOpacity: number;
    strokeWeight: number;
    zoneName: string;
    zoneCode: string;
    tariffCode: string;
    description?: React.ReactNode;
    type: string;
    zIndex: number;
    mode: 'edit' | 'read';
    tariffList: Array<{
      name: string;
      code: string;
    }>;
    onChange?: (
      value: Pick<
        ZoneModalType.Props,
        | 'fillColor'
        | 'strokeColor'
        | 'fillOpacity'
        | 'strokeOpacity'
        | 'strokeWeight'
        | 'zoneName'
        | 'zoneCode'
        | 'tariffCode'
        | 'description'
      >,
    ) => void;
    onClose?: () => void;
    onDelete?: () => void;
  };
}

export const ZoneModal = ({
  className = '',
  fillColor,
  strokeColor,
  fillOpacity,
  strokeOpacity,
  strokeWeight,
  zoneName,
  zoneCode,
  tariffCode,
  description,
  type,
  mode,
  tariffList,
  zIndex,
  onDelete,
  onChange,
  onClose,
}: ZoneModalType.Props) => {
  return mode === 'read' ? (
    <div className={`${className} zoneModalRead`}>
      <div className="zoneModalRead__head">
        <div className="zoneModalRead__head__present">
          <div
            className="zoneModalRead__head__present__color"
            style={{
              backgroundColor: fillColor,
              opacity: fillOpacity,
              border: `${strokeWeight ? (strokeWeight > 4 ? 4 : strokeWeight) : 1}px solid ${strokeColor}`,
            }}
          ></div>

          <div className="zoneModalRead__head__present__text">
            <Write
              data={{ item: zoneName }}
              config={{ mode: 'title-small' }}
            ></Write>

            <Write
              data={{
                item:
                  tariffList.find((tariff) => tariff.code === tariffCode)
                    ?.name || '',
              }}
              config={{ mode: 'value-small' }}
            ></Write>
          </div>
        </div>

        <div className="zoneModalRead__head__type">
          <Badge
            config={{
              text: toCapitalizeCase(type),
              color: 'disabled',
              size: 'small',
            }}
          />
        </div>
      </div>

      {typeof description === 'string' ? (
        <Write
          data={{ item: description }}
          config={{ mode: 'value-small' }}
        ></Write>
      ) : (
        description
      )}
    </div>
  ) : (
    <div className={`${className} zoneModalEdit`}>
      <Write
        data={{ item: 'Modifier la zone' }}
        config={{ mode: 'title-small' }}
      ></Write>

      <Form
        config={{
          validateOnChange: true,
          element: {
            width: 'full',
            height: 'small',
          },
        }}
        data={{
          defaultValue: {
            zoneName,
            zoneCode,
            tariffCode,
            description,
            fillColor,
            strokeColor,
            fillOpacity,
            strokeOpacity,
            strokeWeight,
            zIndex,
          },
        }}
        handleEvent={{
          change: (value) => {
            onChange?.(value);
          },
          validate: (value) => {},
        }}
      >
        <Col config={{ gap: 15 }}>
          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'zoneName',
                label: 'Nom de la zone',
              }}
            >
              <Input
                config={{
                  placeholder: 'Zone verte',
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'zoneCode',
                label: 'Code de la zone',
              }}
            >
              <Input
                config={{
                  placeholder: '123456',
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'tariffCode',
              label: 'Nom du tarif',
            }}
          >
            <Select
              data={{
                items: tariffList.map((tariff) => ({
                  label: tariff.name,
                  value: tariff.code,
                })),
              }}
            />
          </Form.Item>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'description',
              label: 'Description',
              rules: [{ required: false }],
            }}
          >
            <TextArea
              config={{
                placeholder: 'Ecrivez ici la description de la zone',
              }}
            />
          </Form.Item>

          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'fillColor',
                label: 'Couleur de fond',
              }}
            >
              <ColorPicker
                config={{
                  size: 'small',
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'strokeColor',
                label: 'Couleur de bordure',
              }}
            >
              <ColorPicker
                config={{
                  size: 'small',
                }}
              />
            </Form.Item>
          </Row>

          <Row config={{ gap: 10 }}>
            <Form.Item
              config={{
                way: 'vertical',
                name: 'fillOpacity',
                label: 'Opacité de fond',
              }}
            >
              <Input
                config={{
                  width: 'full',
                  type: { name: 'number', min: 0, max: 1, step: 0.1 },
                }}
              />
            </Form.Item>

            <Form.Item
              config={{
                way: 'vertical',
                name: 'strokeWeight',
                label: 'Largeur de bordure',
              }}
            >
              <Input
                config={{
                  width: 'full',
                  type: { name: 'number', min: 0, max: 4, step: 1 },
                }}
              />
            </Form.Item>
          </Row>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'zIndex',
              label: "Ordre d'affichage",
            }}
          >
            <Input
              config={{
                width: 'full',
                type: { name: 'number', min: 0, max: 10000, step: 1 },
              }}
            />
          </Form.Item>
        </Col>
      </Form>

      <Row config={{ gap: 10 }}>
        <Popover.Confirm
          handleEvent={{
            confirm: () => {
              onDelete?.();
            },
          }}
          config={{
            title: 'Action importante',
            description: 'Voulez-vous vraiment supprimer cette zone ?',
            width: 'full',
          }}
        >
          <Button
            config={{ color: 'error', text: 'Supprimer', size: 'full' }}
          ></Button>
        </Popover.Confirm>

        <Button
          handleEvent={{
            click: () => {
              onClose?.();
            },
          }}
          config={{
            color: 'primary',
            text: 'Fermer',
            size: 'full',
          }}
        ></Button>
      </Row>
    </div>
  );
};
