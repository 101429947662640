import Color from 'color';
import { useMemo } from 'react';
import { getCSS } from '../../../functions/css.function';
import { useRootStore } from '../../../hook/useRootStore.hook';

export declare namespace MapsThemeType {
  type Mode = 'read' | 'edit';

  type Props = {
    load: boolean;
  };

  type Return = {
    mapStyle: {
      backgroundColor: string;
      styles: Array<{
        elementType?: string;
        featureType?: string;
        stylers: Array<{
          color?: string;
          visibility?: string;
        }>;
      }>;
    };
    drawingManagerStyle: {
      circle: {
        fillColor: string;
        strokeColor: string;
        fillOpacity: number;
        strokeOpacity: number;
      };
      polygon: {
        fillColor: string;
        strokeColor: string;
        fillOpacity: number;
        strokeOpacity: number;
      };
      polyline: {
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      };
      rectangle: {
        fillColor: string;
        strokeColor: string;
        fillOpacity: number;
        strokeOpacity: number;
        strokeWeight: number;
      };
      marker: {
        animation: google.maps.Animation;
      };
    };
  };
}

export function useMapsTheme({
  load,
}: MapsThemeType.Props): MapsThemeType.Return | null {
  const { ThemeStore } = useRootStore();

  const mapStyle = useMemo(() => {
    return [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-layout')).hex(),
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: new Color(getCSS('--color-layout')).hex(),
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'geometry.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-layout')).hex(),
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'poi.attraction',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.government',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.medical',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-layout')).hex(),
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'poi.place_of_worship',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.school',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.sports_complex',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-intermediate')).hex(),
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: new Color(getCSS('--color-background')).hex(),
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-intermediate')).hex(),
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-label')).hex(),
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-intermediate')).hex(),
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-intermediate')).hex(),
          },
        ],
      },
      {
        featureType: 'water',
        stylers: [
          {
            color: new Color(getCSS('--color-primary')).hex(),
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: new Color(getCSS('--color-primary')).hex(),
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: new Color(getCSS('--color-primary')).hex(),
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];
  }, [ThemeStore.theme]);

  const mapBackground = useMemo(() => {
    return new Color(getCSS('--color-card'))?.hex();
  }, [ThemeStore.theme]);

  const globalDrawingStyle = useMemo(() => {
    return {
      color: new Color(getCSS('--color-yang'))?.hex(),
      opacity: 0.5,
    };
  }, [ThemeStore.theme]);

  if (!load) return null;

  return {
    mapStyle: {
      backgroundColor: mapBackground,
      styles: mapStyle,
    },
    drawingManagerStyle: {
      circle: {
        fillColor: globalDrawingStyle.color,
        strokeColor: globalDrawingStyle.color,
        fillOpacity: globalDrawingStyle.opacity,
        strokeOpacity: 1,
      },
      polygon: {
        fillColor: globalDrawingStyle.color,
        strokeColor: globalDrawingStyle.color,
        fillOpacity: globalDrawingStyle.opacity,
        strokeOpacity: 1,
      },
      polyline: {
        strokeColor: globalDrawingStyle.color,
        strokeOpacity: 1,
        strokeWeight: 1,
      },
      rectangle: {
        fillColor: globalDrawingStyle.color,
        strokeColor: globalDrawingStyle.color,
        fillOpacity: globalDrawingStyle.opacity,
        strokeOpacity: 1,
        strokeWeight: 1,
      },
      marker: {
        animation: google.maps.Animation.DROP,
      },
    },
  };
}
