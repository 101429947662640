import {
  Badge,
  baseFormattedDate,
  baseFormattedDuration,
  Button,
  DatePicker,
  FilterExpanded,
  Form,
  formatAmount,
  Input,
  Page,
  Select,
  Table,
  useLazyQuery,
  useMyUrl,
  useQuery,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Query,
  QueryClientsArgs,
  QueryCsvParkingRightsArgs,
  QueryParkingRightsArgs,
} from '../../../../client/graphql';
import { sortAlphabetically } from '../../../common/array';
import { clientsGql } from '../../clients/gql/clients.gql';
import { csvParkingRightsGql } from '../gql/csvParkingRights.gql';
import { parkingRightsGql } from '../gql/parkingRights.gql';

export const ParkingRightsList = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const { lang } = useTranslation();

  const parkingRightsQuery = useQuery<
    { parkingRights: Query['parkingRights'] },
    QueryParkingRightsArgs
  >(parkingRightsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const clientsQuery = useQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const [csvParkingRights, csvParkingRightsState] = useLazyQuery<
    { csvParkingRights: Query['csvParkingRights'] },
    QueryCsvParkingRightsArgs
  >(csvParkingRightsGql, {
    notification: {
      loading: 'Téléchargement des droits de stationnement en cours ...',
      success: 'Droits de stationnement téléchargés',
      error: 'Erreur lors du téléchargement des droits de stationnement',
    },
  });

  const parkingRights = useMemo(() => {
    if (!parkingRightsQuery.data) return null;

    return parkingRightsQuery.data.parkingRights;
  }, [parkingRightsQuery.data]);

  const clients = useMemo(() => {
    if (!clientsQuery.data) return null;

    return clientsQuery.data.clients;
  }, [clientsQuery.data]);

  useEffect(() => {
    if (csvParkingRightsState.data?.csvParkingRights.downloadURL) {
      window.open(
        csvParkingRightsState.data?.csvParkingRights.downloadURL,
        '_blank',
      );
    }
  }, [csvParkingRightsState.data]);

  return (
    <Page>
      <Widget
        state={{
          error: parkingRightsQuery.error || clientsQuery.error,
          loading: parkingRightsQuery.loading || clientsQuery.loading,
          refetch: () => {
            parkingRightsQuery.refetch();
            clientsQuery.refetch();
          },
        }}
        config={{ title: 'Liste des droits de stationnement' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{
                value: {
                  clientId: getParamsUrl?.clientId,
                  plate: getParamsUrl?.plate,
                  beginEnd: getParamsUrl?.beginEnd,
                },
              }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'clientId',
                    label: 'Clients',
                  }}
                >
                  <Select
                    data={{
                      items: clients
                        ? sortAlphabetically(
                            clients.list.map(({ slug, _id }: any) => ({
                              label: slug,
                              value: _id,
                            })),
                            'label',
                          )
                        : [],
                    }}
                  />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'plate',
                    label: 'Immat.',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.plate }} />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'beginEnd',
                    label: 'Début du stationnement',
                  }}
                >
                  <DatePicker
                    config={{
                      mode: 'range',
                      format: 'datetime',
                    }}
                    data={{ defaultValue: getParamsUrl?.beginEnd }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => {
                      csvParkingRights({
                        variables: { clientId: getParamsUrl?.clientId },
                      });
                    },
                  }}
                  config={{
                    text: 'CSV',
                    icon: 'faFileDownloadSolid',
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              _id: string;
              state?: string | null;
              siteName?: string | null;
              address?: string | null;
              lpn?: string | null;
              tarifName?: string | null;
              amount?: number | null;
              currency?: string | null;
              paymentType?: string | null;
              salesChannel?: string | null;
              startDate?: string | null;
              endDate?: string | null;
              payingDuration?: number | null;
              totalDuration?: number | null;
              internalId?: string | null;
            }>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value) => {
                  navigate(`/onstreet/parking-rights/${value._id}`);
                },
              }}
              data={
                parkingRights
                  ? {
                      list: parkingRights.list.map(
                        ({
                          currency,
                          vehicle,
                          site,
                          error,
                          endDate,
                          startDate,
                          durationDetails,
                          paymentRecordsEntity,
                          siteId,
                          state,
                          amount,
                          internalId,
                          _id,
                          paymentMethod,
                        }) => {
                          let payingDuration = 0;

                          if (
                            durationDetails &&
                            Array.isArray(durationDetails)
                          ) {
                            (durationDetails || [])
                              ?.filter((item) => item?.type === 'Paying')
                              .forEach((item) => {
                                payingDuration += item?.duration || 0;
                              });
                          }

                          const totalDuration =
                            startDate && endDate
                              ? DateTime.fromISO(endDate).diff(
                                  DateTime.fromISO(startDate),
                                  'minutes',
                                ).minutes
                              : 0;

                          return {
                            _id: _id!,
                            address: !site ? 'web' : site?.address1 || null,
                            siteName: site?.name || null,
                            totalDuration: totalDuration || 0,
                            paymentType: paymentMethod,
                            errorCode: error?.code || null,
                            errorMessage: error?.message || null,
                            plate: vehicle?.lpn || null,
                            endDate,
                            startDate,
                            currency,
                            payingDuration: payingDuration || 0,
                            tarifName: 'BASE',
                            salesChannel: siteId ? 'HD' : 'MOBILE',
                            lpn: vehicle?.lpn || null,
                            internalId,
                            state,
                            amount,
                          };
                        },
                      ),
                      paging: parkingRights.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Etat',
                    key: 'state',
                    sort: {
                      sorter: (a, b) => {
                        const sortRef = ['ERROR', 'DONE'];

                        return (
                          sortRef.indexOf(a.state || '') -
                          sortRef.indexOf(b.state || '')
                        );
                      },
                    },
                    render: (value) => {
                      return value.state !== 'DONE' ? (
                        <Badge config={{ color: 'error', text: value.state }} />
                      ) : (
                        <Badge
                          config={{ color: 'success', text: value.state }}
                        />
                      );
                    },
                  },
                  {
                    title: 'Automate',
                    key: 'siteName',
                    sort: {
                      sorter: (a, b) =>
                        `${a.siteName}`.toLowerCase() >
                        `${b.siteName}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Adresse',
                    key: 'address',
                    sort: {
                      sorter: (a, b) =>
                        `${a.address}`.toLowerCase() >
                        `${b.address}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Immat.',
                    key: 'lpn',
                    sort: {
                      sorter: (a, b) =>
                        `${a.lpn}`.toLowerCase() > `${b.lpn}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Nom du tarif',
                    key: 'tarifName',
                    sort: {
                      sorter: (a, b) =>
                        `${a.tarifName}`.toLowerCase() >
                        `${b.tarifName}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Montant total',
                    key: 'amount',
                    sort: {
                      sorter: (a, b) => (b.amount || 0) - (a.amount || 0),
                    },
                    render: (value) =>
                      value.amount
                        ? `${formatAmount({
                            amount: value.amount,
                            currency: null,
                            lang,
                          })}`
                        : null,
                  },
                  {
                    title: 'Devise',
                    key: 'currency',
                    sort: {
                      sorter: (a, b) =>
                        `${a.currency}`.toLowerCase() >
                        `${b.currency}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'paymentType',
                    title: 'Moyen de paiement',
                    render: (value) =>
                      value.paymentType === 'EFT' ? 'CB' : value.paymentType,
                    sort: {
                      sorter: (a, b) =>
                        `${a.paymentType}`.toLowerCase() >
                        `${b.paymentType}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'salesChannel',
                    title: 'Canal de vente',
                    sort: {
                      sorter: (a, b) =>
                        `${a.salesChannel}`.toLowerCase() >
                        `${b.salesChannel}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'startDate',
                    title: 'Début de stationnement',
                    sort: {
                      sorter: (a, b) =>
                        (b.startDate ? new Date(b.startDate).getTime() : 0) -
                        (a.startDate ? new Date(a.startDate).getTime() : 0),
                      defaultSortOrder: 'ascend',
                    },
                    render: (value) =>
                      value.startDate
                        ? baseFormattedDate({ date: value.startDate, lang })
                        : 'Non définie',
                  },
                  {
                    key: 'endDate',
                    title: 'Fin de stationnement',
                    sort: {
                      sorter: (a, b) =>
                        (b.endDate ? new Date(b.endDate).getTime() : 0) -
                        (a.endDate ? new Date(a.endDate).getTime() : 0),
                    },
                    render: (value) =>
                      value.endDate
                        ? baseFormattedDate({ date: value.endDate, lang })
                        : 'Non définie',
                  },
                  {
                    title: 'Durée payante',
                    key: 'payingDuration',
                    sort: {
                      sorter: (a, b) =>
                        (a.payingDuration || 0) - (b.payingDuration || 0),
                    },
                    render: (value) =>
                      value?.payingDuration === 0
                        ? '0'
                        : baseFormattedDuration({
                            minutes: (value.payingDuration || 0) / 60,
                          }),
                  },
                  {
                    title: 'Durée de stationnement',
                    key: 'totalDuration',
                    sort: {
                      sorter: (a, b) =>
                        (a.totalDuration || 0) - (b.totalDuration || 0),
                    },
                    render: (value) =>
                      value?.totalDuration === 0
                        ? '0'
                        : baseFormattedDuration({
                            minutes: value.totalDuration || 0,
                          }),
                  },
                  {
                    title: 'Réference de transaction',
                    key: 'internalId',
                    sort: {
                      sorter: (a, b) =>
                        `${a.internalId}`.toLowerCase() >
                        `${b.internalId}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                    render: (value) => value.internalId || null,
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
