import {
  ApolloError,
  DocumentNode,
  MaybeMasked,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery as useQueryApolloClient,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import { NotificationModeType } from '../store/notification.store';
import { useRootStore } from './useRootStore.hook';
const isAPaging = (data: any) => {
  try {
    const [key, value]: any = Object.entries(data)[0];

    if ('paging' in data?.[key] && 'list' in data?.[key]) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};
export function useQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> & {
    notification?: {
      loading?: string;
      success?: string | ((data: TData) => string);
      error?: string | ((error: ApolloError) => string);
    };
    socket?: Record<
      string,
      (data: any, currentData: TData | undefined) => boolean
    >;
  },
): QueryResult<TData, TVariables> & {
  resetData: () => void;
} {
  const [dataSaved, setDataSaved] = useState<MaybeMasked<TData>>();
  const { NotificationStore, SocketStore } = useRootStore();

  const [stateInterpreter, setStateInterpreter] = useState<{
    loading: boolean | null;
    data: TData | null;
    error: ApolloError | null;
  }>({
    loading: null,
    data: null,
    error: null,
  });

  const state = useQueryApolloClient<TData, TVariables>(query, {
    pollInterval: 0,
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: null,
      }));

      setStateInterpreter({
        data: data,
        loading: false,
        error: null,
      });
    },
    onError: (error) => {
      setNotificationKey((prev) => ({
        ...prev,
        ERROR: null,
      }));

      setStateInterpreter({
        data: null,
        loading: false,
        error: error,
      });
    },
    ...options,
  });

  const notification = options?.notification;
  const [notificationKey, setNotificationKey] = useState<
    Record<
      Extract<NotificationModeType, 'LOADING' | 'SUCCESS' | 'ERROR'>,
      string | null
    >
  >({
    LOADING: null,
    SUCCESS: null,
    ERROR: null,
  });

  useEffect(() => {
    if (!notification) return;

    if (
      notification?.loading &&
      stateInterpreter.loading &&
      !notificationKey.LOADING
    ) {
      const keyLoading = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'LOADING',
        content: notification.loading,
        duration: 100,
        key: keyLoading,
      });

      setNotificationKey((prev) => ({
        ...prev,
        LOADING: keyLoading,
      }));

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.success &&
      stateInterpreter.data &&
      !notificationKey.SUCCESS
    ) {
      const keySuccess = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'SUCCESS',
        content:
          typeof notification.success === 'function'
            ? notification.success(stateInterpreter.data)
            : notification.success,
        key: keySuccess,
      });

      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: keySuccess,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.error &&
      stateInterpreter.error &&
      !notificationKey.ERROR
    ) {
      const keyError = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'ERROR',
        content:
          typeof notification.error === 'function'
            ? notification.error(stateInterpreter.error)
            : notification.error,
        key: keyError,
      });

      setNotificationKey((prev) => ({
        ...prev,
        ERROR: keyError,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }
    }
  }, [notification, stateInterpreter, NotificationStore, notificationKey]);

  useEffect(() => {
    if (SocketStore.client && options?.socket) {
      Object.entries(options.socket).forEach(([key, value]) => {
        SocketStore.client!.on(key, (data) => {
          if (value(data, dataSaved)) {
            if (isAPaging(state.data)) {
              state.refetch({ ...state.variables, page: 1 } as any);
            } else {
              if (dataSaved) {
                state.refetch();
              }
            }
          }
        });
      });
    }

    return () => {
      if (SocketStore.client && options?.socket) {
        Object.entries(options.socket).forEach(([key, value]) => {
          SocketStore.client!.off(key, (data) => {
            if (value(data, dataSaved)) {
              if (dataSaved) {
                state.refetch();
              }
            }
          });
        });
      }
    };
  }, [SocketStore.client, options?.socket, state.refetch, dataSaved]);

  useEffect(() => {
    if (state.data) {
      setDataSaved(state.data);
    }
  }, [state.data]);

  //! TODO: unmount null savedData
  // useEffect(() => {
  //   setDataSaved(undefined);
  // }, [location.pathname]);

  return {
    ...state,
    refetch: (variables?: Partial<TVariables> | undefined) => {
      setStateInterpreter(() => ({
        data: null,
        loading: true,
        error: null,
      }));

      return state.refetch(variables);
    },
    data: dataSaved,
    resetData: () => setDataSaved(undefined),
  };
}
